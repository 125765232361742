import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Prices from "../components/Prices"

export const query = graphql`
  {
    allContentfulPricesItem(sort: { order: ASC, fields: priority }) {
      nodes {
        id
        usluga
        czas
        cena
        priority
      }
    }
  }
`

export default function PricesPage({ data }) {
  const {
    allContentfulPricesItem: { nodes: pricesData },
  } = data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Cennik</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Prices pricesData={pricesData} />
    </>
  )
}
